import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import logo from './logo.png';
import skyline from './background.png';
import progressBackground from './progressBackground.png';

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  background-color: blue !important;
`;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div`
    dsplay: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent !important;
`; // add your styles here

const MintButton = styled(Button)`
  background-color: blue !important;
  font-size: 30px !important;
  &>span {
    color: white;
  }
  &:hover {
    background-color: navy !important;
  }
`; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [tick, setTick] = useState(0);
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
               props.candyMachineId,
               props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
    tick
  ]);

  useEffect(() => {
    console.log('setup')
    const interval = setInterval(() => {
      setTick(Math.random());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  /* useEffect(() => {
   *   var myCanvas = document.createElement('canvas');
   *   document.body.appendChild(myCanvas);

   *   var myConfetti = confetti.create(myCanvas, { resize: true });

   *   myConfetti();
   * }) */

  return (
    <main>
      <nav id="main-nav">
        <h5>Wallet:
          {
            wallet ? (
              <span className="headerValue"> {shortenAddress(wallet.publicKey.toBase58())}</span>
            ) :
            (<span className="headerValue">??????</span>)
          }
        </h5>
        <h5>Balance:
          {
            wallet ? (
              <span className="headerValue"> {(balance || 0).toLocaleString()} SOL</span>
            ) :
            (<span className="headerValue">?????? SOL</span>)
          }
        </h5>
      </nav>
      <img src={logo} alt="Logo" id="logo"/>


      {wallet && (
      <>
        <div className="progressBar">
          <img className="progressBackgroundImage" src={progressBackground} alt="backgroundprogress" />
          <div
            className="progressBarFill"
            style={{
              width: (itemsRemaining / itemsAvailable * 100) + '%'
            }}
          >
          <img className="progressFillBackgroundImage" src={progressBackground} alt="backgroundprogress" />
          </div>
        </div>
        <p>{itemsRemaining} / {itemsAvailable} Heroes Remaining.</p>
      </>
    )}


      {/* {wallet && <p>({itemsRedeemed} already bought!)</p>} */}

    <MintContainer>
      {!wallet ? (
        <ConnectButton>Connect Wallet</ConnectButton>
      ) : (
        <div>
          <MintButton
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <>
                  <CircularProgress />
                </>
              ) : (
                "MINT HERO"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
          {isMinting && (<h5>(Give it a sec... minting)</h5>)}
        </div>
      )}

    <img className="skyline" src={skyline} alt="skyline" />
    </MintContainer>

    <Snackbar
      open={alertState.open}
      autoHideDuration={6000}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
